export function corrigirAcentuacao(texto) {

  const mapaSubstituicoes = {
    "Administra��o": "Administração",
    "Assist�ncia": "Assistência",
    "Finan�as": "Finanças",
    "Secret�ria": "Secretária",
    "Secret�rio": "Secretário",
    "Pol�ticas": "Políticas",
    "P�blicas": "Públicas",
    "Educa��o": "Educação",
    "Sa�de": "Saúde",
  };

  for (let padrao in mapaSubstituicoes) {
    const substituicao = mapaSubstituicoes[padrao];
    const regex = new RegExp(`\\b${padrao}\\w*`, 'gi');
    texto = texto.replace(regex, substituicao);
  }

  return texto;
}