const idPortalApi = "1159e1b8ce98f4b90b3fd0eeeeeb662e1ab72922";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "saoraimundodasmangabeiras.ma.gov.br",

  NomePortal: "São Raimundo das Mangabeiras",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 6, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://saoraimundodasmangabeiras.ma.gov.br/", //usada para link de compartilhamento
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",
  link_mapa: `src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.9094242349647!2d-45.47841139999999!3d-7.0199324999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92d4fb7c46a57fef%3A0x88273940eb296040!2sPrefeitura%20Municipal%20de%20S%C3%A3o%20Raimundo%20das%20Mangabeiras!5e0!3m2!1spt-BR!2sbr!4v1734960557567!5m2!1spt-BR!2sbr"`,
  link_localize: `https://maps.app.goo.gl/BySPUhWUQGhMGnn47`,
  link_youtube: `https://www.youtube.com/channel/UCeE9274sIL-uSmVz0CM3YmA`,
};
